import React, { useState } from 'react';
import Navigation from './screens/navigation';
import NamePicture from './screens/profile';
import { styles } from './styles';
import meImage from './me.png';
// Import your images here
import casmImage from './casm-image.png';
import bscImage from './bsc-image.png';
import upcheiveImage from './upcheive-image.png';
import nflImage from './nfl-image.png';
import LinkedInBlue from './linkedInB.png';
import LinkedInBlack from './LinkedInBL.png';
import QBImage from './QB.webp';
import MLImage from './QB.webp'; // New import for the ML article image

function App() {
  const [activeSection, setActiveSection] = useState('home');
  const [isHovered, setIsHovered] = useState(false);

  const AboutContent = () => {
    const handleImageClick = (e) => {
      e.preventDefault();
      setActiveSection('home');
    };

    return (
      <div style={{ fontSize: '1.3rem', lineHeight: '1.6' }}>
        <h2 style={{ fontSize: '2.2rem', fontWeight: 'bold', marginBottom: '1rem' }}>About Me</h2>
        <a href="https://casmlearning.io/" target="_blank" rel="noopener noreferrer">
          <img src={casmImage} alt="CASM Learning" style={{ width: '240px', height: 'auto', display: 'block', margin: '1rem auto', cursor: 'pointer' }} />
        </a>
        <p>
        I'm a mission-driven learner, coder, and entrepreneurial leader. With skills in Python, Java, React.js, Terraform, and AWS, I currently really love 
        working with LLMs in LangChain to build generative AI applications. I am most passionate about using these technologies to address equality of opportunity 
        in education, and my current venture, CASM Learning, is focused in this domain. Read about why I founded CASM and what we are doing to address education equality here. 
        </p>
        <a href="https://bscanalytics.com/" target="_blank" rel="noopener noreferrer">
          <img src={bscImage} alt="BSC" style={{ width: '240px', height: 'auto', display: 'block', margin: '1rem auto', cursor: 'pointer' }} />
        </a>
        <p>
        Although I have done a lot of independent learning in computer science, without a doubt, I learned more in my internship with BSC Analytics than I 
        learned at any time in my life before that. BSC Analytics provides data modernization and analytics (including machine learning and generative AI)
        to the largest financial services companies in the world, and I was privileged to work with an international team of the best developers, platform engineers, 
        and data professionals on the planet. The BSCA team was so generous with their time and assistance to deepen my technical skills. It was there that I learned 
        how visions get transformed into technical reality. They also taught me Agile ceremony and how to collaborate efficiently on complex dev projects. Their commitment
        to the core values of Speed Learning and personal Ownership (SLO) was inspiring to me as a leader. I hope that one day I can build a company with a value-driven 
        culture as compelling as BSCA's. 
        </p>
        <a href="https://upchieve.org/" target="_blank" rel="noopener noreferrer">
          <img src={upcheiveImage} alt="Upcheive" style={{ width: '240px', height: 'auto', display: 'block', margin: '1rem auto', cursor: 'pointer' }} />
        </a>
        <p>
        When not studying or tinkering, I spend a significant amount of time tutoring underserved students through my favorite not-for-profit, Upcheive. I love Upcheive,
        not only for its commitment to a mission I am passionate about, but also because the Upcheive team is very adept at execution. I especially admire the Upcheive 
        technology platform's simplicity and overall design as well as the community of dedicated tutors all committed to the same mission. I had initially thought of starting
        a similar not-for-profit, but a competitive analysis quickly told me that I could have far more impact by focusing my efforts with Upcheive. Read more about my work 
        with Upcheive here. Hopefully it will inspire you to get involved or to support Upcheive financially. 
        </p>
        <a href="/" onClick={handleImageClick}>
          <img src={nflImage} alt="NFL Data Science" style={{ width: '240px', height: 'auto', display: 'block', margin: '1rem auto', cursor: 'pointer' }} />
        </a>
        <p>
        Finally, I am an avid NFL fan (GO DOLPHINS!) and love to apply data science to NFL statistics. Read about my latest data science work on NFL quarterbacks and see my
        predictions and fantasy recommendations here. I also really love fishing, but I have not had a desire to apply technology to this endeavor. I prefer the battle of 
        wits with my adversary to be unaided by technology more advanced than a line and a pole--even if I lose the majority of the time. 
        </p>
      </div>
    );
  };

  const HomeContent = () => (
    <div>
      <h2 style={{ fontSize: '2.2rem', fontWeight: 'bold', marginBottom: '1rem' }}>Welcome to My Personal Website</h2>
      <div style={{ marginBottom: '2rem' }}>
        <h3 style={{ fontSize: '1.8rem', fontWeight: 'bold', marginBottom: '1rem' }}>Featured Articles</h3>
        
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '1rem' }}>
          {/* First column */}
          <div style={{ flex: '1 1 calc(50% - 0.5rem)' }}>
            {/* CASM Learning article */}
            <a href="https://www.linkedin.com/pulse/why-i-founded-casm-learning-nolan-johnson-oke8c/?trackingId=Fk3tIRNMZaaGzICmUCNY8A%3D%3D" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', display: 'block', marginBottom: '1rem' }}>
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                border: '1px solid #e2e8f0', 
                borderRadius: '8px', 
                padding: '0.67rem', 
                transition: 'box-shadow 0.3s',
                height: '133px'
              }}>
                <img src={casmImage} alt="CASM Learning" style={{ width: '133px', height: 'auto', marginRight: '0.67rem', borderRadius: '4px' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginBottom: '2rem' }}>
                  <h4 style={{ fontSize: '1.33rem', fontWeight: 'bold', marginBottom: '0.33rem' }}>Why I Founded CASM Learning</h4>
                  <p style={{ fontSize: '0.9rem', color: '#4a5568'}}>Learn about the equality-in-education problem that drove me to build a technical solution and ultimately found a company. (6 min read)</p>
                </div>
              </div>
            </a>

            {/* New ML article */}
            <a href="https://bscanalytics.com/insights/predicting-yards-passing-for-nfl-quarterbacks-using-machine-learning-part-2" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', display: 'block', marginBottom: '1rem' }}>
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                border: '1px solid #e2e8f0', 
                borderRadius: '8px', 
                padding: '0.67rem', 
                transition: 'box-shadow 0.3s',
                height: '133px'
              }}>
                <img src={MLImage} alt="Machine Learning in Education" style={{ width: '133px', height: 'auto', marginRight: '0.67rem', borderRadius: '4px' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginBottom: '2rem' }}>
                  <h4 style={{ fontSize: '1.33rem', fontWeight: 'bold', marginBottom: '-0.5rem' }}>Predicting Yards Passing for NFL QB Using ML Part 2</h4>
                  <p style={{ fontSize: '0.9rem', color: '#4a5568'}}>Discover how machine learning is transforming NFL quarterback predictions as I dive into selection and training of ML models to forecast passing yards. (12 min read)</p>
                </div>
              </div>
            </a>
          </div>

          {/* Second column */}
          <div style={{ flex: '1 1 calc(50% - 0.5rem)' }}>
            {/* NFL QB Analysis Part 1 */}
            <a href="https://bscanalytics.com/insights/predicting-yards-passing-for-nfl-quarterbacks-using-machine-learning-part-1" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', display: 'block', marginBottom: '1rem' }}>
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                border: '1px solid #e2e8f0', 
                borderRadius: '8px', 
                padding: '0.67rem', 
                transition: 'box-shadow 0.3s',
                height: '133px'
              }}>
                <img src={QBImage} alt="NFL Quarterback Analysis" style={{ width: '133px', height: 'auto', marginRight: '0.67rem', borderRadius: '4px' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' , marginBottom: '2rem'}}>
                  <h4 style={{ fontSize: '1.33rem', fontWeight: 'bold', marginBottom: '-.5rem' }}>Predicting Yards Passing for NFL QB Using ML Part 1</h4>
                  <p style={{ fontSize: '0.9rem', color: '#4a5568'}}>Discover how machine learning is transforming NFL quarterback predictions as I select, visualize and clean a the dataset to forecast total passing yards for NFL QBs. (10 min read)</p>
                </div>
              </div>
            </a>

            {/* NFL QB Analysis Part 2 */}
            <a href="https://bscanalytics.com/insights/predicting-yards-passing-for-nfl-qbs-with-machine-learning-part-3" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit', display: 'block' }}>
              <div style={{ 
                display: 'flex', 
                alignItems: 'center', 
                border: '1px solid #e2e8f0', 
                borderRadius: '8px', 
                padding: '0.67rem', 
                transition: 'box-shadow 0.3s',
                height: '133px'
              }}>
                <img src={QBImage} alt="NFL Quarterback Analysis" style={{ width: '133px', height: 'auto', marginRight: '0.67rem', borderRadius: '4px' }} />
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%', marginBottom: '2rem' }}>
                  <h4 style={{ fontSize: '1.33rem', fontWeight: 'bold', marginBottom: '-0.5rem' }}>Predicting Yards Passing for NFL QB Using ML Part 3</h4>
                  <p style={{ fontSize: '0.9rem', color: '#4a5568'}}>Uncover the performance and insights of our NFL quarterback prediction model as we evaluate its accuracy and highlight key findings from the data. (13 min read) </p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <p>Explore my website to learn more about my projects, passions, and experiences in technology and education.</p>
    </div>
  );

  const AchievementsContent = () => (
    <div>
      <h2 style={{ fontSize: '1.5rem', fontWeight: 'bold', marginBottom: '1rem' }}>Achievements</h2>
      <ul style={{ fontSize: '1.3rem', lineHeight: '1.6' }}>
        <li>Coming soon :)</li>
      </ul>
    </div>
  );

  const renderContent = () => {
    switch (activeSection) {
      case 'about':
        return <AboutContent />;
      case 'achievements':
        return <AchievementsContent />;
      default:
        return <HomeContent />;
    }
  };

  return (
    <div style={{ minHeight: '100vh', display: 'flex' }}>
      {/* Left column (grey, full height) */}
      <aside style={{
        width: '25%',
        backgroundColor: '#f3f4f6', // Light grey background
        padding: '2rem',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between', // This will push content to top and bottom
        position: 'relative',
        ...styles.app
      }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <NamePicture
             name="Nolan Johnson"
             imageUrl={meImage}
          />
          <div style={{
            marginTop: '1rem',
            textAlign: 'center',
            fontSize: '1rem',
            fontWeight: 'bold',
            color: '#00', 
            maxWidth: '80%'
          }}>
            Mission-Driven Learner, Coder, Entrepreneur
          </div>
          <a
            href="https://www.linkedin.com/in/nolan-johnson-baa88b306/"
            target="_blank"
            rel="noopener noreferrer"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{ marginTop: '0.5rem' }}
          >
            <img
              src={isHovered ? LinkedInBlack : LinkedInBlue}
              alt="LinkedIn"
              style={{ width: '32px', height: '32px' }}
            />
          </a>
        </div>
        <div style={{
          marginTop: 'auto', // This pushes the div to the bottom
          textAlign: 'center',
          fontSize: '1.1rem',
          color: '#4a5568',
          padding: '1rem',
          borderTop: '1px solid #cbd5e0',
          width: '100%'
        }}>
          <p>Welcome to my personal website!</p>
          <p style={{ marginTop: '0.5rem', fontSize: '0.9rem' }}>Feel free to explore and learn more about me and my work.</p>
        </div>
      </aside>

      {/* Right side (navigation and main content) */}
      <div style={{ width: '75%', display: 'flex', flexDirection: 'column' }}>
        {/* Top navigation */}
        <header style={{
          width: '100%',
          backgroundColor: '#ffffff',
        }}>
          <Navigation onNavClick={setActiveSection} activeSection={activeSection} />
        </header>

        {/* Main content area */}
        <main style={{ flex: 1, padding: '2rem' }}>
          {renderContent()}
        </main>
      </div>
    </div>
  );
}

export default App;